<template>
  <section class="resources">
    <PageHeader>
      Resources
    </PageHeader>
    <div class="resources__content">
      <div class="titles">
        <h1 class="resources__title">
          <span class="resources__title-row">
            Welcome to our marketplace! This feature of UnGhosted will connect you with
            vetted domain experts to help you with anything you might need.
          </span>
        </h1>
        <h3 class="resources__title">
          <span class="resources__title-row">
            We're currently in build mode (of the marketplace) and would love your input!
          </span>
        </h3>
      </div>
      <div class="content-wrap">
        <img
          src="/img/resources-bubble.png"
          width="410"
          height="119"
          class="resources__message-bubble"
          alt=""
        >
        <v-form class="resources__form">
          <span class="resources__form-options-title">Select all that applies:</span>
          <ResourcesOptions :options="optionsList" @selectOption="selectOption" />
          <label class="resources__form-label">
            What else can we help you with? You can add to your response later.
          </label>
          <v-textarea
            solo
            rounded
            flat
            no-resize
            hide-details
          />
          <AppButton
            width="172"
            height="37"
            dark
            color="var(--button-primary-color)"
            class="resources__form-submit"
            @click="sendFeedback"
          >
            Submit
          </AppButton>
        </v-form>
      </div>
    </div>
    <SuccessFeedbackModal :is-success-feedback-modal-open.sync="isSuccessFeedbackModalOpen" />
  </section>
</template>

<script>
import ResourcesOptions from '@/components/ResourcesOptions'
import SuccessFeedbackModal from '@/components/SuccessFeedbackModal'

export default {
  name: 'Resources',
  components: {
    ResourcesOptions,
    SuccessFeedbackModal
  },
  data() {
    return {
      optionsList: [
        {
          title: 'Resume writing',
          selected: false
        },
        {
          title: 'LinkedIn branding',
          selected: false
        },
        {
          title: 'Cover letters',
          selected: false
        },
        {
          title: 'Interview tips',
          selected: false
        },
        {
          title: 'Industry news',
          selected: false
        },
        {
          title: 'Ability to connect with other job seekers',
          selected: false
        },
        {
          title: 'Connecting with a career coach',
          selected: false
        }
      ],
      isSuccessFeedbackModalOpen: false
    }
  },
  methods: {
    selectOption(selectedOption) {
      const optionToSelect = this.optionsList.find(option => option === selectedOption)
      optionToSelect.selected = selectedOption.selected
    },
    sendFeedback() {
      this.isSuccessFeedbackModalOpen = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.resources {
  @include default-page-paddings;

  .titles {
    margin: 0 auto;
    max-width: 950px;
  }

  &__title {
    margin: 0 auto 35px;
    font-size: var(--font-size-h1);
    text-align: center;

    &-row {
      background: linear-gradient(to bottom, transparent 50%, var(--background-secondary-color) 50%);
    }
  }

  &__content {
    margin-top: 80px;

    @include breakpoint-reverse(small) {
      margin-top: 40px;
    }
  }

  .content-wrap {
    margin: 0 auto;
    max-width: 780px;

    @include breakpoint-reverse(medium) {
      margin-left: 0;
    }
  }

  &__message-bubble {
    display: block;
    margin-left: auto;
  }

  &__form {
    display: grid;

    &-options-title {
      margin-top: 14px;
      margin-bottom: 17px;
      font-weight: var(--font-weight-light);
      font-size: var(--font-size-base-sm);
      text-align: center;
    }

    &-label {
      margin-top: 50px;
      margin-bottom: 10px;
    }

    &-submit {
      justify-self: center;
      margin-top: 45px;
      box-shadow: none;

      &::v-deep .text {
        font-size: var(--font-size-sub);
        text-shadow: none;
      }
    }
  }
}
</style>
