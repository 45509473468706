<template>
  <div class="resources-options">
    <span
      v-for="(option, i) in options"
      :key="i"
      class="resources-options__item"
      :class="{ 'resources-options__item_selected': option.selected }"
      @click="selectOption(option)"
    >
      {{ option.title }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'ResourcesOptions',
  props: {
    options: {
      type: Array,
      required: true
    }
  },
  methods: {
    selectOption(option) {
      option.selected = !option.selected
      this.$emit('selectOption', option)
    }
  }
}
</script>

<style lang="scss" scoped>
.resources-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-self: center;
  max-width: 550px;
  gap: 20px;

  &__item {
    border-radius: var(--border-radius-default);
    padding: 5px 30px;
    box-shadow: var(--text-shadow-default);
    background-color: var(--background-about-color);
    transition: box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
    cursor: pointer;

    &_selected {
      box-shadow: none;
      background-color: var(--link-color);
    }
  }
}
</style>
