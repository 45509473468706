<template>
  <v-dialog
    v-model="isOpen"
    transition="dialog-top-transition"
    max-width="502"
    content-class="success-feedback-modal"
    overlay-color="var(--overlay-bg-color)"
    overlay-opacity="1"
    @click:outside="closeModal"
  >
    <button class="close-button" @click="closeModal">
      <img src="/img/icons/plus-black.svg" alt="">
    </button>
    <span class="modal-title">
      Your response has been submitted. You can add or edit your input at any time.
    </span>
    <span class="modal-text">
      Thank you!
    </span>
  </v-dialog>
</template>

<script>
export default {
  name: 'SuccessFeedbackModal',
  props: {
    isSuccessFeedbackModalOpen: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  watch: {
    isSuccessFeedbackModalOpen(value) {
      this.isOpen = value
    }
  },
  methods: {
    closeModal() {
      this.$emit('update:isSuccessFeedbackModalOpen', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-dialog__content::v-deep .success-feedback-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: var(--border-radius-default);
  padding: 50px 65px 60px;
  background-color: var(--background-secondary-color);
}

.modal-text {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-lg);
  text-align: center;
}

.close-button {
  position: absolute;
  right: 14px;
  top: 14px;
  width: 15px;
  height: 15px;
  transform: rotate(45deg);

  img {
    width: 15px;
    height: 15px;
  }
}

.modal-title {
  display: block;
  margin-bottom: 12px;
  max-width: 270px;
  font-weight: var(--font-weight-light);
  font-size: var(--font-size-base-sm);
  text-align: center;
}
</style>
